@import "../../variables/variables.scss";

.landing-page {
  font-family: "Russo One", serif;
  .landing-nav {
    background-color: $background-color;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 1000;
    .logo {
      width: 140px;
      margin-left: -10px;
    }
    .nav-btn {
      color: $white-color;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  .landing-content {
    // padding-top: 80px;
    background-color: $background-color;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    .image-grid:first-child {
      padding-top: 80px;
    }

    .image-grid {
      display: flex;
      width: 100%;
      height: 100vh;
      scroll-snap-align: start;

      .image-item {
        position: relative;
        min-width: 650px;
        max-width: 650px;

        img {
          display: block;
        }

        .caption-2 {
          display: none;
        }
      }

      .blue-box {
        background-color: $light-blue;
      }
      .black-box {
        background-color: $background-color;
      }

      .blank {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 0 10px 10px 0;
        .caption {
          color: $white-color;
          text-transform: uppercase;
          font-size: 16px;
          text-align: center;
        }
      }
    }

    .bg-image {
      scroll-snap-align: start;
      padding: 1rem;
      background: url("../../assets/images/couple_img.webp") no-repeat center
        center;
      background-size: cover;
      height: calc(100vh - 80px) !important;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      .text {
        color: $white-color;
        font-size: 25px;
      }

      .download-btn {
        padding: 0 !important;
        width: min-content;
        font-family: "Russo One", serif;
        font-weight: 400;
        color: $light-blue;
      }
    }
  }
}

// @media only screen and (min-width: 1600px) {
//   .landing-page {
//     .landing-content .bg-image {
//       // min-height: 950px;
//     }

//     .landing-content {
//       .image-grid {
//         .image-item {
//           // width: 50%;
//         }
//       }
//     }
//   }
// }

@media only screen and (max-width: 1600px) {
  .landing-page {
    .landing-content .bg-image {
      height: 950px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .landing-page {
    .landing-nav .logo {
      margin-right: 30px;
    }
    .landing-content .image-grid .blank .caption {
      font-size: 22px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .landing-page {
    .landing-content .bg-image {
      height: 850px;
    }
  }
}

@media only screen and (max-width: 1020px) {
  .landing-page {
    .landing-nav .logo {
      margin-right: 30px;
    }
    .landing-content .image-grid .blank {
      justify-content: center;
      .caption {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .landing-page {
    .landing-nav .logo {
      margin-right: 30px;
    }
    .landing-content .image-grid .blank {
      .caption {
        display: none;
      }
    }

    .landing-content .image-grid .image-item {
      width: 100%;
      position: relative;
      img {
        display: block;
      }

      .caption-2 {
        width: 100%;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        color: $white-color;
        text-transform: uppercase;
        font-size: 18px;
        text-align: center;
        display: block;
        text-shadow: 2px 1px #3b3a3a;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .landing-page {
    .landing-nav {
      padding: 0 30px;
      .logo {
        margin-left: -10px;
      }
    }

    .landing-content .bg-image {
      height: 500px;
    }

    .landing-content .image-grid {
      .image-item {
        min-width: 300px;
      }
      .blank {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .landing-page {
    .landing-content .image-grid .image-item {
      img {
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .landing-page {
    .landing-nav {
      padding: 0 20px;
      .logo {
        width: 120px;
      }
      .nav-btn {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 370px) {
  .landing-page {
    .landing-nav {
      padding: 0 15px;
      .nav-btn {
        font-size: 11px;
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .landing-page {
    .landing-nav {
      padding: 0 5px;
      .nav-btn {
        font-size: 10px;
      }
    }
  }
}
