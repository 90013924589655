@import "../../variables/variables.scss";

.gorceryList-container {
  width: 80%;
  max-width: 400px;
  .ant-form {
    margin-bottom: 1rem;
    width: 100%;
    .ant-form-item {
      margin-inline-end: 0;
    }

    .item_name {
      width: 35%;
      .ant-row {
        height: 100%;
        .ant-form-item-control-input {
          height: 100%;
          .ant-form-item-control-input-content {
            height: 100%;
          }
          .ant-input {
            border-radius: 16px 0 0 16px;
            padding: 11.25px 16px;
          }
        }
      }
    }

    .item_qty {
      width: 29%;
      .ant-input-number {
        border-radius: 0;
        width: 100%;
        height: 46px;
        background: transparent;
        border: $form-input-border;
        border-left: none;
        min-width: 80px;
        .ant-input-number-input-wrap {
          height: 100%;
          input {
            height: 100%;
          }
          input::placeholder {
            color: $text-grey !important;
          }
        }
        .ant-input-number-handler-wrap {
          background: transparent;
          .ant-input-number-handler {
            border: none;
          }

          .ant-input-number-handler-up {
            border-start-end-radius: 0px;
            .anticon-up {
              color: $text-grey;
            }
          }
          .ant-input-number-handler-down {
            border-end-end-radius: 0px;
            .anticon-down {
              color: $text-grey;
            }
          }
        }
        .ant-input-number-input-wrap {
          .ant-input-number-input {
            color: $white-color;
            width: 100%;
          }
        }
      }
    }

    .quantity_unit {
      width: 29%;
      .ant-select-selector {
        border-radius: 0 !important;
        border-left: none !important;
        .ant-select-selection-item {
          color: $white-color !important;
        }
      }
    }

    .ant-select .ant-select-arrow {
      top: 75%;
    }

    .action-buttons {
      width: 6%;
      border: $form-input-border;
      border-radius: 0 16px 16px 0;
      border-left: none;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .btn-container {
        cursor: pointer;
        height: 50%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .anticon {
          color: $white-color;
          svg {
            height: 12px;
            width: 12px;
          }
        }
      }
      .delete {
        border-bottom: $form-input-border;
      }
    }
  }
  .completed-item {
    border-radius: 16px;
    .ant-input-outlined.ant-input-disabled,
    .ant-input-outlined[disabled] {
      color: $white-color;
      background-color: $nav-backgound-color;
      border-right: 1px solid $background-color;
    }
    .item_qty {
      background-color: $nav-backgound-color;
      .ant-input-number .ant-input-number-input {
        background-color: $nav-backgound-color;
        cursor: not-allowed;
      }
    }

    .ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
      .ant-select-selector {
      background: $nav-backgound-color !important;
      border-right: 1px solid $background-color !important;
      cursor: not-allowed;
    }

    .action-buttons {
      background-color: $nav-backgound-color;

      .btn-container {
        cursor: pointer;
        height: 50%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .delete {
        border-bottom: 1px solid $background-color;
      }
    }
  }
}

.pop-confirm {
  .ant-popover-content {
    .ant-popover-inner {
      background-color: $nav-backgound-color;
      .ant-popconfirm-message {
        .ant-popconfirm-title {
          color: $white-color;
        }
      }

      .ant-popconfirm-buttons {
        display: flex;
        justify-content: flex-end;

        .ant-btn {
          cursor: pointer;
          width: auto;
          padding: 5px !important;
          &:hover,
          &:active {
            background: transparent;
          }
        }

        .yes-btn {
          color: $light-blue;
        }
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .gorceryList-container {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .gorceryList-container {
    width: 100%;
  }
}
