@import "../../../variables/variables.scss";

.add-grocery-btn {
  margin-bottom: 16px;
  max-width: 400px;
  width: 80%;
  background: transparent;
  font-weight: 500;
  border: 2px dashed $nav-backgound-color;
  border-radius: 16px;
  padding: 12px !important;

  .btn-text {
    color: $text-grey;
  }
  .anticon-plus-circle {
    color: $light-blue;
  }
  &:hover,
  &:active {
    background: transparent !important;
    border: 2px dashed $nav-backgound-color !important;
  }
}

#grocery-form {
  max-width: 400px;
  width: 80%;
  .ant-space {
    .ant-space-item {
      .ant-form-item {
        margin-bottom: 9.5px;
      }
    }
  }
  .item_name,
  .item_qty,
  .quantity_unit {
    margin-bottom: -10px;
  }
  .item_name {
    width: 40%;
  }
  .item_qty {
    width: 20%;
  }
  .quantity_unit {
    width: 30%;
    .ant-select-selection-item {
      color: $white-color !important;
    }
    .ant-form-item-explain-error {
      margin-top: 1rem;
    }
  }
  .ant-input {
    height: 46px;
  }
  .ant-input-number {
    width: 100%;
    height: 46px;
    background: transparent;
    border: $form-input-border;
    border-left: none;
    min-width: 80px;
    .ant-input-number-input-wrap {
      height: 100%;
      input {
        height: 100%;
      }
      input::placeholder {
        color: $text-grey !important;
      }
    }
    .ant-input-number-handler-wrap {
      background: transparent;
      .ant-input-number-handler {
        border: none;
      }

      .ant-input-number-handler-up {
        border-start-end-radius: 0px;
        .anticon-up {
          color: $text-grey;
        }
      }
      .ant-input-number-handler-down {
        border-end-end-radius: 0px;
        .anticon-down {
          color: $text-grey;
        }
      }
    }
    .ant-input-number-input-wrap {
      .ant-input-number-input {
        color: $white-color;
        width: 100%;
      }
    }
  }
  .ant-select-selector {
    border-radius: 0 !important;
  }
  .ant-select .ant-select-arrow {
    top: 75%;
  }
  .action-btn {
    padding: 0 !important;
    width: 46px;
    height: 46px;
  }
  .delete {
    background-color: $red;
    &:hover {
      background-color: $red;
    }
  }

  .done {
    border-radius: 0 16px 16px 0;
    background-color: $green;
    &:hover {
      background-color: $green;
    }
  }

  .ant-form-item .ant-form-item-explain-error {
    padding: 0;
    text-align: center;
  }
}

@media screen and (max-width: 930px) {
  .add-grocery-btn {
    width: 90%;
  }

  #grocery-form {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .add-grocery-btn {
    width: 100%;
  }

  #grocery-form {
    #grocery-form {
      width: 100%;
    }
    width: 100%;
  }
}
