@import "../../variables/variables.scss";

.footer-container {
  background: $background-color;
  font-family: $font-family;
  padding: 2rem 2rem;
  .about-links {
    display: flex;
    justify-content: space-between;
    .section {
      display: flex;
      flex-direction: column;
      width: 300px;
      h1 {
        color: $white-color;
        margin-block-start: 0;
        margin-block-end: 1rem;
      }
    }
    .logo-description {
      .logo {
        width: 200px;
        margin-bottom: 1rem;
      }

      .description {
        color: $white-color;
        font-size: 1rem;
      }
    }

    .contact-container {
      .support {
        display: flex;
        align-items: center;
        .message-icon {
          margin-right: 10px;
          filter: brightness(0) saturate(100%) invert(57%) sepia(46%)
            saturate(867%) hue-rotate(155deg) brightness(93%) contrast(87%);
        }
        .email {
          color: $white-color;
          font-size: 1rem;
        }
      }
    }

    .links-container {
      .links {
        display: flex;
        flex-direction: column;
        a {
          .anticon-caret-right {
            color: $light-blue;
            margin-right: 10px;
            margin-bottom: 1rem;
          }
        }
        span {
          color: $white-color;
          font-size: 1rem;
        }
      }
      .contact2-container {
        display: none;
      }
    }
  }
  .footer-divider {
    border-block-start: 1px solid $nav-backgound-color;
  }
  .copyright-social-section {
    display: flex;
    justify-content: space-between;
    .copyright {
      color: $white-color;
    }
    .icons-container {
      width: 150px;
      display: flex;
      justify-content: space-between;
      .anticon {
        color: $nav-backgound-color;
        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .footer-container {
    .about-links {
      .section {
        width: 200px;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .footer-container {
    .about-links {
      .section {
        width: 150px;
      }
      .logo-description {
        .logo {
          width: 150px;
        }
        .description {
          font-size: 14px;
        }
      }
      .contact-container {
        .support {
          .email {
            font-size: 14px;
          }
        }
      }
      .links-container {
        .links {
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer-container {
    padding: 2rem 1rem;
  }
}

@media only screen and (max-width: 550px) {
  .footer-container {
    .about-links {
      .section {
        width: 200px;
      }
      .contact-container {
        display: none;
      }
      .links-container {
        .contact2-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          .support {
            display: flex;
            align-items: center;
            .message-icon {
              margin-right: 10px;
              filter: brightness(0) saturate(100%) invert(57%) sepia(46%)
                saturate(867%) hue-rotate(155deg) brightness(93%) contrast(87%);
            }
            .email {
              color: $white-color;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .footer-container {
    padding: 2rem;
    .about-links {
      flex-direction: column;
      .section {
        width: 100%;
      }
      .logo-description {
        margin-bottom: 1rem;
      }
    }
    .copyright-social-section {
      .copyright {
        font-size: 12px;
      }
      .icons-container {
        width: 100px;
      }
    }
  }
}
