@import "../../variables/variables.scss";

.settings-container {
  padding: 3rem 1rem;
  display: flex;
  justify-content: space-between;

  .edit-profile,
  .settings {
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    min-width: 280px;
    width: calc(100% - 42px);
    margin-right: auto;
    margin-left: auto;
  }

  .edit-profile {
    .user-name {
      margin-top: 5px;
      text-transform: capitalize;
      color: $white-color;
      font-size: 20px;
      font-weight: 700;
    }

    .user-email {
      font-size: 12px;
      font-weight: 500;
      color: $white-color;
      margin-bottom: 10px;
    }

    // .ant-progress {
    //   .ant-progress-text {
    //     font-size: 12px;
    //   }
    // }

    .animated-progress {
      .ant-progress-bg {
        transition: width 1s ease-in-out;
      }
      .ant-progress-text {
        transition: opacity 1s ease-in-out;
        font-size: 12px;
      }
    }

    .verification-check-content {
      color: $red;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      margin-top: 7px;
      margin-bottom: 10px;
    }

    .edit-profile-btn {
      padding: 8px 12px !important;
      width: 140px;
      margin-top: 15px;
      font-size: 12px;
      font-weight: 600;
      &:active {
        color: $white-color;
      }
    }
  }

  .settings {
    .title-name {
      color: $white-color;
      margin-top: 0;
      margin-bottom: 2rem;
    }

    .card-btn {
      border-radius: 16px;
      padding: 1rem 1.25rem !important;

      &:hover {
        color: $white-color;
      }
    }

    .action-btn {
      margin-bottom: 1rem;
      justify-content: flex-start;

      .action {
        height: 20px;
        width: 20px;
      }

      .inbox {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    .theme-btn {
      justify-content: space-between;
      margin-bottom: 1rem;

      .Theme,
      .Dark {
        display: flex;
        align-items: center;
      }

      .Theme {
        .theme {
          margin-right: 7px;
          height: 20px;
          width: 20px;
        }
      }
      .Dark {
        span {
          margin-right: 5px;
          color: #a2a2b5;
          font-size: 12px;
          font-weight: 500;
        }
        .arrow {
          height: 10px;
          width: 10px;
        }
      }
    }
  }
}

.ant-modal-root .ant-modal-wrap {
  top: 156px;
}

.confirm-modal {
  width: 300px !important;
  .display-none {
    display: none;
  }

  .ok-button {
    width: 40px;
    padding: 4px !important;
    display: inline;
    margin-inline-start: 0px;
    font-size: 11px;
  }
}

@media screen and (max-width: 625px) {
  .settings-container {
    padding: 3rem 0;
    flex-direction: column;

    .v-divider {
      display: none;
    }

    .settings {
      margin-top: 1.5rem;
      .title-name {
        display: none;
      }
    }
  }
}
